<template>
      <div class="vx-row">
        <div class="vx-col w-full mb-base">
          <vx-card title="" class="mb-5">
            <vs-tabs>
                  <vs-tab label="Info" class='pt-5'>

                        <div class="vx-row">
                          <div class="vx-col w-full lg:w-1/2" >
                              
                              <table class="info sm:w-full md:w-full">
                                <tr>
                                  <td class="font-semibold">Police No</td>
                                  <td>{{dt.police_no}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Vehicle No.</td>
                                  <td>{{dt.code}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Color</td>
                                  <td>{{dt.color}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Chassis No</td>
                                  <td>{{dt.chassis_no}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Machine No</td>
                                  <td>{{dt.machine_no}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Fuel Capacity</td>
                                  <td>{{dt.bbm_capacity}}</td>
                                </tr>
                              </table>
                          </div>
                          <div class="vx-col w-full lg:w-1/2">
                              
                              <table class="info">
                                <tr>
                                  <td class="font-semibold">Vehicle Type</td>
                                  <td>{{dt.vehicle_type}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Owner</td>
                                  <td>{{dt.owner}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Daily Distance (km)</td>
                                  <td>{{dt.daily_km}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Carrying Capacity</td>
                                  <td>{{dt.carrying_capacity}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">IMEI</td>
                                  <td>{{dt.imei}}</td>
                                </tr>
                              </table>
                          </div>      
                        </div>
                  </vs-tab>
                  <vs-tab label="Shipment">
                    <shipment :vehicle_id='$route.params.id'></shipment>
                  </vs-tab>
            </vs-tabs>
          </vx-card>
        </div>
      </div>

</template>

<script>
import { mapActions } from 'vuex'
import Shipment from '@pages/operational/shipments/Index'

export default {
  data(){
    return {
      dt: {},
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null
    }
  },
  components : {
     Shipment
  },
  methods:{
    ...mapActions({
      dispatchShow: 'vehicles/show',
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('vehicles/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    async confirmDeleteCost(){
      try {
        await this.$store.dispatch('vehicles/delete_costs', this.idDeleteCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        // console.log(error);
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>